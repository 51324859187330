import React from 'react';
import { Link } from 'react-router-dom';
import './Homepage.css'; // Import external CSS file
import Banner from '../Banner/Banner';
import btcHandGif  from './btc-hand.gif'
import exploreGif from './explore.gif'
import accountGif from './account.gif'
import verifyGif from './verify.gif'
import depositGif from './deposit.gif'
import tradingGif from './trading.gif'
import {FaInstagram, FaWhatsapp} from 'react-icons/fa'
import {MdEmail} from 'react-icons/md'

const Homepage = () => {
  
  return (
    <div className="home-page">
    <div className='banner-section'>
      <div className="hero-section">
        <div className='banner-text'>
        <Banner/>
        
       <div className="explore">
          <Link to="/subscription" className="cta-button">
            Explore Subscription Plans
          </Link>
          <img src={exploreGif} alt="btcGif" className="explore-gif" />
        </div>
        </div>
       
      </div>
      
      </div>
      <div className='text-Home'>
      <div className='text-Content'>
        <h1>Fx Trading Hub </h1>
          <p>is made so even investors with zero trading  </p>
          <p>experience are successful</p>
        <h3>Our program, unlike any other, guarantees fix hourly </h3>
        <h3>interest</h3>
        <p>making a profit.  for investors regardless of  </p>
        <p>market conditions.</p>
      </div>
      <div className='text-Home2'>
      <img src={btcHandGif} alt="btcGif" className="custom-img" />
        <div className="text-paragraph"><p>Check out our services briefly</p></div>
        
      </div>

      </div>
      <section className="key-features">
        <div><h2>Key Features</h2></div>
        <div className="feature">
        <div className="feature-card">
          <h3>Real-Time Market</h3>
          <h3>Data</h3>
          <p>Access up-to-the-minute forex market data and exchange rates.</p>

          <div className="explore">
          <Link to="/overview" className="cta-button">
           Overview
          </Link>
          <img src={exploreGif} alt="btcGif" className="explore-gif" />
        </div>
        <div className="explore">
          <Link to="/getfunded" className="cta-button">
            Investment Plan
          </Link>
          <img src={exploreGif} alt="btcGif" className="explore-gif" />
        </div>

        </div>
        <div className="feature-card">
          <h3>Advanced Trading </h3>
          <h3>Bots</h3>
          <p>Utilize powerful trading bots to execute strategies and optimize your trades.</p>
          <div className="explore">
          <Link to="/bots" className="cta-button">
            Purchase Bot
          </Link>
          <img src={exploreGif} alt="btcGif" className="explore-gif" />
        </div>
        </div>
        </div>
        {/* Add more feature cards */}
      </section>

      

      

      <section className="newsletter-signup">
        <h2>Subscribe to Our Newsletter</h2>
        <form className="newsletter-form">
          <input type="email" placeholder="Enter your email" />
          <button type="submit" className="explore" >Subscribe</button>
        </form>
      </section>


      <section className="about-us">
            
          <div className='about-us-text'> 
            <h2>About Us</h2>
            <p>Here, We offer New Clients <strong>30%</strong> First Deposit Bonus When you deposit anywhere between $100 and $100,000</p>
            <button className='explore'>Start Trading Now</button>
            
            <p>Your Forex Trading Hub is dedicated to providing you with the latest market trends, trading insights, and educational resources.</p>
            <p>Our team of experienced traders and analysts is committed to helping you succeed in the world of forex trading.</p>
          </div>
          <div className='about-us-procedure'>
            <div className='steps'>
            <img src={accountGif} alt="accountGif" className="stepsGif" />

              <h1> Open An Account</h1>
              <p>Submit Your Details</p>
            </div>
            <div className='steps'>
            <img src={verifyGif} alt="verifyGif" className="stepsGif" />

            <h1> Get Verified (Ticket Number)</h1>
              <p>Get Your Account Verified by our onboarding team</p>
            </div>
            <div className='steps'>
            <img src={depositGif} alt="dipositGif" className="stepsGif" />

              <h1> Make A Deposit</h1>
              <p>Make a deposit and fund your trading account with a minimum of $100 or equivalent in your Local Currency</p>
            </div>
            <div className='steps'>
            <img src={tradingGif} alt="tradingGif" className="stepsGif" />

            <h1> Start Trading</h1>
              <p>Your Bonus will be credited to your funded trading account within 6 hours</p>
            </div>

          </div>
      </section>

      <section className="contact">
        <h2>Contact Us</h2>
        <p>If you have any questions or inquiries, feel free to reach out to us on: </p>
        <div className='social-media'>
          <Link to="https://intagram.com"><FaInstagram className='icon'/> Instagram</Link>
          <Link to="https://intagram.com"><FaWhatsapp className='icon'/> Whatsapp</Link>
          <Link to="https://intagram.com"><MdEmail className='icon'/> Email</Link>

        </div>
      </section>

    </div>
  );
}

export default Homepage;
