import React from 'react'
import './SignalsCard.css'
import depositGif from './deposit.gif'
import payoutGif from './payout.gif'
import membersGif from './members.gif'
import onlineGif from './online.gif'


function SignalsCard() {
  return (

   <div>
    <div className='signalCards'>
      
      <div className='mini-card'>
      <img src={depositGif} alt="mini-card-gif" className="mini-card-gif" />
            <p>$ 745509.57</p>
            <p>TOTAL DEPOSITED</p>
      </div>
      <div className='mini-card'>
      <img src={membersGif} alt="membersgif" className="mini-card-gif" />
            <p>215</p>
            <p>TOTAL MEMBERS</p>
            
      </div>
      <div className='mini-card'>
      <img src={payoutGif} alt="payout" className="mini-card-gif" />
            <p>$ 7504145.35</p>
            <p>TOTAL PAYMENTS</p>
      </div>
      <div className='mini-card'>
      <img src={onlineGif} alt="online-gif" className="mini-card-gif" />
            <p>3740</p>
            <p>DAYS ONLINE</p>
      </div>
  </div>



 


   </div>
  )
}

export default SignalsCard
