import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import LoginForm from './Components/Access/Login/Login';
import RegistrationForm from './Components/Access/Registration/Register';
import Profile from './Components/Profile/Profile';
import Getfunded from './Components/GetFunded/Getfunded';
import Homepage from './Components/HomePage/Homepage';
import TradingBots from './Components/HomePage/TradingBots/TradingBots';
import ForexEdu from './Components/TradingEdu/ForexEdu';
import ForexMarketOverview from './Components/Overview/ForexMarketOverview';
import ChoosePlan from './Components/Plans/ChoosePlan';
import Navbar from './Components/NavBar/Navbar';
import Footer from './Components/Footer/Footer';
import Dashboard from './UserProfile/Dashboard';
function App() {
  const [darkMode, setDarkMode] = useState(true);

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  return (
    <div className={`App ${darkMode ? 'dark-mode' : 'light-mode'}`}>
      <Router>
        <Navbar darkMode={darkMode} toggleDarkMode={toggleDarkMode} />
        <Routes>
          <Route path="/" element={<Homepage darkMode={darkMode} toggleDarkMode={toggleDarkMode} />}  />
          <Route path="/profile" element={<Profile darkMode={darkMode} toggleDarkMode={toggleDarkMode} />} />
          <Route path="/getfunded" element={<Getfunded darkMode={darkMode} toggleDarkMode={toggleDarkMode} />} />
          <Route path="/login" element={<LoginForm darkMode={darkMode} toggleDarkMode={toggleDarkMode} />}  />
          <Route path="/bots" element={<TradingBots darkMode={darkMode} toggleDarkMode={toggleDarkMode} />}  />
          <Route path="/learn" element={<ForexEdu darkMode={darkMode} toggleDarkMode={toggleDarkMode} />}  />
          <Route path="/overview" element={<ForexMarketOverview darkMode={darkMode} toggleDarkMode={toggleDarkMode} />}  />
          <Route path="/subscription" element={<ChoosePlan darkMode={darkMode} toggleDarkMode={toggleDarkMode} />}  />
          <Route path="/register" element={<RegistrationForm darkMode={darkMode} toggleDarkMode={toggleDarkMode} />}  />
          <Route path="/dashboard" element={<Dashboard darkMode={darkMode} toggleDarkMode={toggleDarkMode} />}  />

        </Routes>
        <Footer/>

      </Router>
    </div>
  );
}

export default App;
