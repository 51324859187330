import React from 'react';
import './ForexMarketOverview.css'; // Import external CSS file

const ForexMarketOverview = () => {
  return (
    <section className="forex-market-overview">
            <div className='sub-title'>Forex Market Overview</div>

      <div className="market-information">
        <h3>Understanding the Forex Market</h3>
        <p>The forex (foreign exchange) market is the largest and most liquid financial market in the world. It involves the exchange of currencies from different countries, and its primary purpose is to facilitate international trade and investment.</p>
        <p>Trading in the forex market occurs 24 hours a day, five days a week, due to the global nature of the market and the involvement of major financial centers around the world, such as New York, London, Tokyo, and Sydney.</p>
      </div>
      <div className="trading-basics">
        <h3>Basics of Trading in the Forex Market</h3>
        <p>At its core, forex trading involves buying one currency while simultaneously selling another. These transactions are carried out in currency pairs, where the first currency in the pair is the base currency and the second currency is the quote currency.</p>
        <p>For example, in the EUR/USD currency pair, the euro (EUR) is the base currency, and the US dollar (USD) is the quote currency. The exchange rate represents how much of the quote currency is needed to purchase one unit of the base currency.</p>
      </div>
      <div className="how-it-works">
        <h3>How the Forex Market Works</h3>
        <p>The forex market operates through a network of participants, including banks, financial institutions, corporations, governments, and individual traders. It doesn't have a central exchange like the stock market; instead, it operates over-the-counter (OTC), meaning that trades are conducted electronically through a decentralized network.</p>
        <p>Live forex market data and exchange rates are constantly changing due to the interaction of supply and demand. Economic indicators, geopolitical events, interest rate decisions, and market sentiment all play a role in influencing currency prices.</p>
      </div>
      <div className="live-market-data">
        <h3>Live Forex Market Data and Trends</h3>
        <p>Staying informed about real-time forex market data is crucial for traders and investors. Forex brokers and platforms provide access to live currency prices, charts, and financial news to help traders make informed decisions.</p>
        <p>Market trends can be analyzed through technical analysis, which involves studying price charts and patterns, and fundamental analysis, which examines economic indicators and news events that impact currency values.</p>
      </div>
      {/* Add more content as needed */}
    </section>
  );
};

export default ForexMarketOverview;
