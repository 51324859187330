import React from 'react';
import './TradingBots.css'; // Import external CSS file
import {FcCheckmark} from 'react-icons/fc'
import {BsCheck2Circle} from "react-icons/bs";


const TradingBots = () => {
  return (
    <section className="trading-bots">
      
     


      <div className='sub-title'>Trading Bots</div>
      
      <div className='overviewSection'>
        <div className="bots-overview">
          <h3>Overview of Our Trading Bot Offerings</h3>
          <p>Discover how our advanced trading bots can enhance your trading experience. Our trading bots are designed to automate various trading strategies, allowing you to execute trades without the need for constant manual monitoring. Whether you're a seasoned trader or just starting out, our trading bots provide a powerful tool to optimize your trading activities.</p>
        </div>
        <div className='subscription-tier '>
           <div> 
            <h2>Purchase Bot</h2>
            <p>Our Premium plan offers the ultimate forex trading experience with all the tools and insights you need to succeed.</p>
            <ul>
              <li><BsCheck2Circle className="checkIcon"/> Access to premium trading bots and algorithms</li>
              <li><BsCheck2Circle className="checkIcon"/> Exclusive market analysis from experts</li>
              <li><BsCheck2Circle className="checkIcon"/> 24/7 customer support</li>
            </ul>
            <h1 className='price'> $100.00</h1>
            </div>
            <div className='btn-test'>
                          
                  <button >Purchase</button>

            </div>
        </div>
      </div>
      
      
      

      <div className="how-bots-work">
        <h3>How Trading Bots Work</h3>
        <p>Trading bots, also known as algorithmic trading systems, use predefined rules and algorithms to analyze market data and execute trades on your behalf. These rules are based on technical indicators, price patterns, and other factors. Once set up, the trading bot can monitor the market 24/7, identify trading opportunities, and execute trades with precision and speed. This automation eliminates emotional decision-making and allows for quick reactions to market movements.</p>
      </div>
      <div className="bot-strategies">
        <h3>Bot Strategies</h3>
        <p>Our trading bots offer a range of strategies to suit different trading preferences. Some common bot strategies include:</p>
        <ul>
          <li><FcCheckmark/> Scalping: Rapid, short-term trades to capture small price movements.</li>
          <li><FcCheckmark/> Trend Following: Riding the momentum of established market trends.</li>
          <li><FcCheckmark/> Arbitrage: Exploiting price differences of the same asset on different exchanges.</li>
          <li><FcCheckmark/> Mean Reversion: Capitalizing on the tendency of prices to revert to their historical averages.</li>
        </ul>
        <p>Each strategy has its own risk-reward profile, and our trading bots allow you to customize and backtest these strategies to find the best fit for your trading goals.</p>
     
     
      
     
     
     
      </div>
    </section>
  );
};

export default TradingBots;
