import React from 'react';
import './Register.css';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';

const Signup= ({ darkMode }) => {

  const history = useNavigate();

  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');

  async function submit(e){
    e.preventDefault();
      try{
        await axios.post("http://locahost:8000/register", {
          email,
          password
        })

        .then(res=>{
          if(res.data==="exist"){
              alert("user exist")
          }
          else if (res.data==="notexist"){
            history("/", {state:{id:email}})
          }
        })
        .catch(e=>{
            alert("wrong details")
            console.log(e);
        })
        
      }
      catch(e){
        console.log(e)
      }
  }

  return (
    <div classNameName={`login-form ${darkMode ? 'dark-mode' : 'light-mode'}`}>
      <h2>Sign Up</h2>
      <form action="POST">
      
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <input type="submit" handle={submit}/>
        
        <br />
        <div>
          <p>Have an account?</p>
          <Link to="/login">Login</Link>
        </div>
        <br />
      </form>
    </div>
  );
};

export default Signup;
