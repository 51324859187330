import React from 'react';
import './Login.css';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';

const LoginForm = ({ darkMode }) => {

    const history = useNavigate();

  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');

  async function submit(e){
    e.preventDefault();
      try{
        await axios.post("http://locahost:8000/login", {
          email,
          password
        })

        .then(res=>{
          if(res.data==="exist"){
              history("/", {state:{id:email}})
          }
          else if (res.data!=="exist"){
            alert("user not found, recheck credentials or signup")
        }
        })
        .catch(e=>{
            alert("wrong details")
            console.log(e);
        })
        
      }
      catch(e){
        console.log(e)
      }
  }

  return (
    <div className={`login-form ${darkMode ? 'dark-mode' : 'light-mode'}`}>
      <h2>Login</h2>
      <form action="POST">
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <input type="submit" handle={submit}/>
        
        <br />
        <div>
          <p>Don't have an account?</p>
          <Link to="/register">Signup instead</Link>
        </div>
        <br />
      </form>
    </div>
  );
};

export default LoginForm;
