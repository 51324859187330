import React from 'react';
import './DepositPlan.css';

function DepositPlan() {
  return (
    <div className='deposit-plan'>
 <div className="subscription-tier">
          <h3>STARTER PLAN</h3>
          <p>7% HOURLY FOR 24 HOURS</p>
          <p>Minimum Deposit: $100</p>
          <p>Maximum Deposit: $500</p>

          <div className="previous-price">WAS $49.99/month</div>

          <div className="price">$49.99/MONTH</div>
          <div className='signup-btn'>
            <button className="sign-up-button">Sign Up</button>
          </div>
        </div>
      
      <div className="subscription-tier">
          <h3>PREMIUM PLAN</h3>
          <p>40% HOURLY FOR 24 HOURS</p>
          <p>Minimum Deposit: $500</p>
          <p>Maximum Deposit: $1,000</p>

          <div className="previous-price">WAS $49.99/month</div>

          <div className="price">$49.99/MONTH</div>
          <div className='signup-btn'>
            <button className="sign-up-button">Sign Up</button>
          </div>
        </div>
      
      <div className="subscription-tier">
          <h3>ADVANCE PLAN</h3>
          <p>40% HOURLY FOR 24 HOURS</p>
          <p>Minimum Deposit: $3,000</p>
          <p>Maximum Deposit: $10,000</p>

          <div className="previous-price">WAS $49.99/month</div>

          <div className="price">$49.99/MONTH</div>
          <div className='signup-btn'>
            <button className="sign-up-button">Sign Up</button>
          </div>
        </div>
      
      <div className="subscription-tier">
          <h3>VIP Plan</h3>
          <p>20% HOURLY FOR 24 HOURS</p>
          <p>Minimum Deposit: $1,000</p>
          <p>Maximum Deposit: $3,000</p>

          <div className="previous-price">WAS $49.99/month</div>

          <div className="price">$49.99/MONTH</div>
          <div className='signup-btn'>
            <button className="sign-up-button">Sign Up</button>
          </div>
        </div>

     


    </div>
  );
}

export default DepositPlan;
