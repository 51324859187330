import React from 'react'
import { FaInstagram, FaWhatsapp, FaTelegram} from 'react-icons/fa'
import { MdEmail } from 'react-icons/md';
import './Footer.css'
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <div className='Footer'>
    <div className='footer-sections'>
    <div className='footer-section'>
    <h3>Services</h3>

         <div className='footer-content'> <Link to="/subscription">Signal-Subscription</Link></div>
         <div className='footer-content'><Link to="/getfunded">Get Funded</Link></div>
         <div className='footer-content'><Link to="/bots">Trading Bots</Link></div>
         <div className='footer-content'><Link to="/learn">Learning</Link></div>
         <div className='footer-content'><Link to="/overview" >Fx-Overview</Link></div>

    </div>

    <div className='footer-section'>
        <h3>Plans</h3>
      <div className='footer-content'>Basic Plan</div>
      <div className='footer-content'>Pro Plan</div>
      <div className='footer-content'>Premium Plan</div>
    </div>

    <div className='footer-section'>
    <h3>Contact Us</h3>

    <div className='footer-content'> <Link to="/subscription"><MdEmail/> Email</Link></div>
         <div className='footer-content'><Link to="/getfunded"><FaInstagram/> Instagram</Link></div>
         <div className='footer-content'><Link to="/bots"><FaWhatsapp/> Whatsapp</Link></div>
         <div className='footer-content'><Link to="/learn"><FaTelegram/> Telegram</Link></div>
    </div>
    <div className='footer-section'>
    <h3>Wealth</h3>

      <div className='footer-content'>Crypto</div>
      <div className='footer-content'>Stocks</div>
      <div className='footer-content'>Commodities</div>
      <div className='footer-cotent'>Vaults</div>
    </div>
    
    
    </div>
    <p>Copyright © 2023 Forex-Traders-Hub. All rights reserved.</p>
    </div>
  )
}

export default Footer
