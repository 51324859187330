import React from 'react';
import {BsCheck2Circle} from "react-icons/bs";
import './ChoosePlan.css'; // Import external CSS file

const ChoosePlan = () => {
    


  return (
    <section className="choose-plan">
            <div className='sub-title'>Subscription Plans</div>
      <div className="subscription-tiers">
        <div className="subscription-tier">
          <h3>Basic Plan</h3>
          <p>Our Basic plan is perfect for beginners who want to explore the world of forex trading with essential tools and resources.</p>
          <ul>
            <li><BsCheck2Circle className="checkIcon"/> Access to real-time market data</li>
            <li><BsCheck2Circle className="checkIcon"/> Basic trading bot features</li>
            <li><BsCheck2Circle className="checkIcon"/> Educational resources</li>
          </ul>
          <div className="previous-price">WAS $49.99/MONTH</div>
          <div className="price">$49.99/month</div>

             <button className="sign-up-button">Sign Up</button>
        </div>
        <div className="subscription-tier">
          <h3>Pro Plan</h3>
          <p>Upgrade to our Pro plan for advanced features that cater to experienced traders looking to optimize their strategies.</p>
          <ul>
            <li><BsCheck2Circle className="checkIcon"/> Advanced trading bots with customizable strategies</li>
            <li><BsCheck2Circle className="checkIcon"/> Premium educational content</li>
            <li><BsCheck2Circle className="checkIcon"/> Technical analysis tools</li>
          </ul>
          <div className="previous-price">WAS $49.99/MONTH</div>

          <div className="price">$49.99/month</div>

            <button className="sign-up-button">Sign Up</button>
        </div>
        <div className="subscription-tier">
          <h3>Premium Plan</h3>
          <p>Our Premium plan offers the ultimate forex trading experience with all the tools and insights you need to succeed.</p>
          <ul>
            <li><BsCheck2Circle className="checkIcon"/> Access to premium trading bots and algorithms</li>
            <li><BsCheck2Circle className="checkIcon"/> Exclusive market analysis from experts</li>
            <li><BsCheck2Circle className="checkIcon"/> 24/7 customer support</li>
          </ul>
          <div className="previous-price">WAS $49.99/month</div>

          <div className="price">$49.99/MONTH</div>
            <button className="sign-up-button">Sign Up</button>
        </div>
      </div>
      <div className='convincing'>
      <div className="convincing-text">
       
        Ready to take your forex trading journey to the next level? Choose the subscription plan that fits your needs and start maximizing your trading potential today. With our expertly designed trading bots, real-time data, and comprehensive educational resources, you'll have the tools you need to succeed in the dynamic world of forex trading. Don't miss out – sign up now and unlock a world of trading possibilities!
      
      </div>
      <div className='convincing-image'>
        Dont Forget To Subscribe to your Plan as the offer stands, Thank You!
      </div>
      </div>
    </section>
  );
};

export default ChoosePlan;
