import React, { useState, useEffect } from "react";
import './Profile.css';

function Profile() {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % 4);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div>
      <div classNameName="compGetFunded">
        <div classNameName="compSummary">
          <h1>Get Funded</h1>
        </div>
        <div classNameName="compReview">
          <div classNameName="slider">
            <div style={{ transform: `translateX(-${currentIndex * 100}%)` }}>1st Review</div>
            <div style={{ transform: `translateX(-${currentIndex * 100}%)` }}>2nd Review</div>
            <div style={{ transform: `translateX(-${currentIndex * 100}%)` }}>3rd Review</div>
            <div style={{ transform: `translateX(-${currentIndex * 100}%)` }}>4th Review</div>
          </div>
        </div>
      </div>
      
      <div classNameName="compBots">
      <div classNameName="services">
            <div >1st Review</div>
            <div >1st Review</div>
            <div >1st Review</div>
            <div >1st Review</div>
          </div>     
       </div>
      
      <div classNameName="compSubSignal">
      <div classNameName="Signals">
            <div >1st Review</div>
            <div >1st Review</div>
            <div >1st Review</div>
            <div >1st Review</div>
          </div>      </div>
    </div>
  );
}

export default Profile;
