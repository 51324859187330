import React from 'react';
import './ForexEdu.css'; // Import external CSS file
import {BsCheck2Circle} from 'react-icons/bs'
const ForexEdu = () => {
  return (
    <section className="forex-edu">
      <div className='sub-title'>Learning Forex</div>
      <div className="tutorials">
        <h3>Tutorials and Learning Resources</h3>
        <p>Our Forex Education section provides a comprehensive collection of tutorials, articles, and videos to help you master forex trading concepts. Whether you're a beginner or an experienced trader looking to enhance your skills, our educational resources cover a wide range of topics.</p>
        <p>Explore topics such as:</p>
        <ul>
          <li><BsCheck2Circle className='checkIcon'/>  Introduction to forex markets and trading</li>
          <li><BsCheck2Circle/> Understanding currency pairs and exchange rates</li>
          <li><BsCheck2Circle/> Technical and fundamental analysis</li>
          <li><BsCheck2Circle/> Risk management and trading psychology</li>
          <li><BsCheck2Circle/> And much more...</li>
        </ul>
      </div>
      <div className="glossary">
        <h3>Forex Glossary for Beginners</h3>
        <p>Our Forex Glossary is a valuable tool for beginners to familiarize themselves with essential forex trading terminology. We understand that the world of forex trading can be overwhelming with jargon, so we've compiled a user-friendly glossary that explains key terms and concepts in simple language.</p>
        <p>Whether you're confused by terms like "pip," "spread," or "leverage," our glossary has got you covered with easy-to-understand definitions and explanations.</p>
      </div>
      <div className="advanced-strategies">
        <h3>Advanced Trading Strategies</h3>
        <p>For experienced traders seeking to take their trading to the next level, our Advanced Trading Strategies section offers in-depth insights into complex trading techniques and strategies.</p>
        <p>Explore advanced topics such as:</p>
        <ul>
          <li><BsCheck2Circle/> Swing trading and trend following</li>
          <li><BsCheck2Circle/> Scalping and day trading strategies</li>
          <li><BsCheck2Circle/> Algorithmic and quantitative trading</li>
          <li><BsCheck2Circle/> Options and derivatives in forex</li>
          <li><BsCheck2Circle/> And much more...</li>
        </ul>
        <p>Unlock the potential of your trading with these advanced strategies and elevate your success in the competitive forex market.</p>
      </div>
    </section>
  );
};

export default ForexEdu;
