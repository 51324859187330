import React from 'react';
import SignalsCard from '../Signals/SignalsCard';
import DepositPlan from '../DepositPlan/DepositPlan';
import './Getfunded.css'
//import ForexGraph from '../ForexGraph/ForexGraph';

function Getfunded() {
  

  return (
    <div classNameName="getfunded">
            <>

            <DepositPlan/>
            </>

            <>
             <SignalsCard/>    
            </>
            

        
    </div>
  );
}

export default Getfunded;
