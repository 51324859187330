import React from 'react';
import './Banner.css';

function Banner() {
  const text = 'Forex Trading Hub';

  return (
    <div className="banner-component">
      <div className="banner">
        <div className="container">
          <div className="text">
            {text.split('').map((char, index) => {
              const spanStyles = {
                '--i': index + 1,
              };
              return (
                <span key={index} style={spanStyles}>
                  {char}
                </span>
              );
            })}
          </div>
        </div>
        
        <h3>Welcome to Your Forex Trading Hub</h3>
         
        <div className='banner-offer-text'>30% Bonus On First Deposit</div>
        
        <p>Your source for real-time market data, </p>
        <p> trading bots, </p>
        <p>and expert analysis.</p>
      </div>
    </div>
  );
}

export default Banner;
