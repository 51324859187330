import React from 'react'

function Dashboard() {
  return (
    <div className='userprofile'>
      Hello User,
      {/*TODO, MenuBar Sticked at the bottom with 5 react icons and label on small devices and on large devices sticked at the left. 5 icons for user profile, funding, MySubscription, Deposit, Withdraw each icon with label*/}
      {/*TODO, Use React Router Dom to navigate ensure to highlight active icon */}
      
    </div>
  )
}

export default Dashboard
